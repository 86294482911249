import { BaseTextFieldProps, ButtonProps, ChipProps } from "@mui/material";
import { SxProps } from "@mui/material/styles";
import { Duration } from "date-fns";
import { MuiTelInputCountry } from "mui-tel-input";
import { IImage } from "./shared";

type Width = "one_third" | "half" | "two_thirds" | "full";

type FieldReturnTypes = string | string[] | number | number[] | Date | null;

export type Grouping = {
  key: string;
  value: { or: string[] | { and: string[] }[] };
};

export interface ISelectOption {
  name: string;
  label?: { primary?: string; secondary?: string; content?: string };
  icon?: IImage;
  groupings?: { label?: string } & ({ or?: Grouping[] } | { and?: Grouping[] });
}

export type SelectRenderOption = Pick<ISelectOption, "label" | "icon"> & {
  key: string;
  value: any;
  firstLetter: string;
  groupingLabel: string;
};

export type FilterBy = {
  key: string;
  value: { or: string[] | { and: string[] }[] };
};

export interface IFormField {
  id: string;
  name: string;
  label: string;
  helperText?: string;
  required: boolean;
  disabled: boolean;
  width: Width;
  color: NonNullable<BaseTextFieldProps["color"]>;
  size: NonNullable<BaseTextFieldProps["size"]>;
  icon?: IImage;
  defaultByGroups?: ({ defaultValue: FieldReturnTypes } & (
    | { or: FilterBy[] }
    | { and: FilterBy[] }
  ))[];
  visible?: boolean;
  visibleByGroups?: ({ or: FilterBy[] } | { and: FilterBy[] })[];
  styles?: SxProps;
  validationType?: string;
  validationTypeError?: string;
  validations?: { type: string; params: any[] }[];
}

type FieldType = {
  type:
    | "ComponentFormText"
    | "ComponentFormTextArea"
    | "ComponentFormNumber"
    | "ComponentFormAutoComplete"
    | "ComponentFormDate"
    | "ComponentFormCheckboxGroup"
    | "ComponentFormRadioGroup"
    | "ComponentFormPhoneNumber";
};

interface ITextFieldType extends FieldType {
  fieldType: "text" | "email" | "password";
  variant: NonNullable<BaseTextFieldProps["variant"]>;
  title?: string;
  placeholder?: string;
  autoComplete?: string;
}
export interface ITextField extends IFormField, ITextFieldType {}

interface ITextAreaType extends FieldType {
  variant: NonNullable<BaseTextFieldProps["variant"]>;
  placeholder?: string;
  rows: number;
}
export interface ITextArea extends IFormField, ITextAreaType {}

interface INumberFieldType extends FieldType {
  variant: NonNullable<BaseTextFieldProps["variant"]>;
  placeholder?: string;
  step?: number;
  min?: number;
  max?: number;
  fieldVariant?: "default" | "quantity";
}
export interface INumberField extends IFormField, INumberFieldType {}

interface IAutoCompleteType extends FieldType {
  variant: NonNullable<BaseTextFieldProps["variant"]>;
  inputVariant: "select" | "autocomplete";
  title: string;
  placeholder?: string | null;
  multiple: boolean;
  selectVariant: "select" | "checkbox";
  chipVariant: ChipProps["variant"];
  chipsPlacement: "inside" | "outside";
  disableClearable?: boolean;
  grouping?: { symbols: boolean; digits: boolean };
  autoCompleteOptions: ISelectOption[];
}
export interface IAutoComplete extends IFormField, IAutoCompleteType {}

interface IDateFieldType extends FieldType {
  variant: NonNullable<BaseTextFieldProps["variant"]>;
  title?: string;
  disableOpenPicker?: boolean;
  minDate?: string;
  maxDate?: string;
  blockingRanges?: { from: string; to: string }[];
  dynamicMinDate?: Duration;
  dynamicMaxDate?: Duration;
  dynamicBlockingRanges?: { from: Duration; to: Duration }[];
}
export interface IDateField extends IFormField, IDateFieldType {
  onClose?: () => void;
  onOpen?: () => void;
}

interface ICheckboxGroupType extends FieldType {
  title?: string;
  itemWidth: Width;
  checkboxPosition: "top" | "center";
  variant: NonNullable<ButtonProps["variant"]> | "inverse";
  checkboxGroupOptions: ISelectOption[];
}
export interface ICheckboxGroup extends IFormField, ICheckboxGroupType {}

interface IRadioGroupType extends FieldType {
  variant: NonNullable<ButtonProps["variant"]>;
  radioGroupOptions: ISelectOption[];
}
export interface IRadioGroup extends IFormField, IRadioGroupType {}

interface IPhoneNumberFieldType extends FieldType {
  variant: NonNullable<BaseTextFieldProps["variant"]>;
  title?: string;
  placeholder?: string;
  onlyCountries?: MuiTelInputCountry[];
}
export interface IPhoneNumberField extends IFormField, IPhoneNumberFieldType {}

export type FormField =
  | ITextField
  | ITextArea
  | INumberField
  | IAutoComplete
  | IDateField
  | ICheckboxGroup
  | IRadioGroup
  | IPhoneNumberField;

export interface IForm<T = FieldReturnTypes> {
  [x: string]: T;
}

export interface IFormComponent {
  fields: FormField[];
  submitButtonText?: string;
}

export type FormFieldOptions = {
  id: string;
  name: string;
  options: ISelectOption[];
};

export type FormFieldOptionsData = {
  id: string;
  field: {
    name: string;
  };
  fieldType: {
    autoCompleteOptions?: ISelectOption[];
    checkboxGroupOptions?: ISelectOption[];
    radioGroupOptions?: ISelectOption[];
  }[];
};

export interface IFormFieldResponse {
  id: string;
  field: IFormField;
  fieldType: (FormField & {
    __component?:
      | "form.text"
      | "form.text-area"
      | "form.number"
      | "form.auto-complete"
      | "form.date-field"
      | "form.checkbox-group"
      | "form.radio-group"
      | "form.phone-number";
  })[];
}

const selectOptionsPopulate = {
  populate: {
    icon: true,
    label: true,
    groupings: { populate: { or: { populate: "*" }, and: { populate: "*" } } },
  },
  limit: -1,
};

export const formPopulate = {
  form: {
    populate: {
      fields: {
        populate: {
          field: {
            populate: {
              icon: true,
              defaultByGroups: { populate: { or: { populate: "*" }, and: { populate: "*" } } },
              visibleByGroups: { populate: { or: { populate: "*" }, and: { populate: "*" } } },
              validations: { populate: "*" },
            },
          },
          fieldType: {
            on: {
              "form.text": { populate: "*" },
              "form.text-area": { populate: "*" },
              "form.number": { populate: "*" },
              "form.auto-complete": {
                populate: {
                  grouping: { populate: "*" },
                  autoCompleteOptions: selectOptionsPopulate,
                },
              },
              "form.date-field": {
                populate: {
                  blockingRanges: { populate: "*" },
                  dynamicMinDate: { populate: "*" },
                  dynamicMaxDate: { populate: "*" },
                  dynamicBlockingRanges: { populate: "*" },
                },
              },
              "form.checkbox-group": {
                populate: {
                  checkboxGroupOptions: selectOptionsPopulate,
                },
              },
              "form.radio-group": {
                populate: {
                  radioGroupOptions: selectOptionsPopulate,
                },
              },
              "form.phone-number": { populate: "*" },
            },
          },
        },
      },
    },
  },
};
